import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="intro-to-content-styles" mdxType="Subpage">
      <h3>{`Introduction to Content Styles`}</h3>
      <Callout number="7.1" term="Working with type..." color="alternate" mdxType="Callout">
        <p>{`...on web and in print have many similar components; technical logistics differ.`}</p>
      </Callout>
      <p>{`While we looked at an overview of CSS in the last module and learned about some technical logistics, it’s now time to get on to the fun part! We finally get to think beyond how our web pages are structured and on to actually altering how they look.`}</p>
      <p>{`We'll read more about working with type on the web when we read from the Miller text again later in the course. For now let's stay focused on the technical logistics.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp. 264–270, 273–276, and 279–288 in Ch. 12
and pp. 330–340 in Ch. 14 of Duckett.`}</p>
      </Callout>
      <Callout number="7.2" term="CSS properties for controlling type..." color="alternate" mdxType="Callout">
        <p>{`...include `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`color`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`font-family`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`font-size`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`font-weight`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`font-style`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`text-align`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`letter-spacing`}</code>{` (kerning), and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`line-height`}</code>{` (leading) along with several other properties that come in handy for further style and finesse.`}</p>
      </Callout>
      <p>{`These provide an excellent summary of the CSS properties that affect many aspects of how text is presented on the web. Here is a table summarizing the most commonly used properties, what each does, and common values or settings:`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Property`}</th>
            <th parentName="tr" {...{
              "align": "left"
            }}>{`What it does`}</th>
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Value options`}</th>
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Examples`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`color`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Sets the color of the text`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Color keyword, hex, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`rgb()`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`rgba()`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`hsl()`}</code>{`, or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`hsla()`}</code>{` color code`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`color: #ef4520;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`font-family`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Sets the typeface`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Comma-separated list of font faces to use; first item is used, but if not available, next item is used, etc.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`font-family: Verdana, Arial, sans-serif;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`font-size`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Sets the size of text`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Desired font size follow immediately by the abbreviation for the desired unit of measurement, likely either `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`em`}</code>{` or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`rem`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`font-size: 1.4rem;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`font-weight`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adds or removes boldface`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`bold`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`normal`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`font-weight: bold;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`font-style`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adds or removes italics`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`italic`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`normal`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`font-style: italic;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`text-transform`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Sets the casing for text`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`uppercase`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`lowercase`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`capitalize`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`text-transform:uppercase;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`text-decoration`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adds or removes underlines and other text decorations`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`none`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`underline`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`overline`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`line-through`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`blink`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`text-decoration: underline;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`text-align`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Sets alignment`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`left`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`right`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`center`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`justify`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`text-align: left;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`text-indent`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Sets indentation`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Desired indentation measurement followed immediately by the abbreviation for the unit of measurement, likely either `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`em`}</code>{` or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`px`}</code>{`.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`text-indent: 20px;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`text-shadow`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adds or removes a drop shadow on text.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`See p.288 of Duckett text.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`line-height`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adjusts leading`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Desired leading measurement followed immediately by the abbreviation for the unit of measurement, likely `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`em`}</code>{` or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`%`}</code>{`.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`line-height: 1.3em;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`letter-spacing`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adjusts kerning`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Desired kerning measurement followed immediately by the abbreviation for the unit of measurement, likely `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`em`}</code>{` or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`px`}</code>{`.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`letter-spacing: 0.1em;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`list-style`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adds or removes bullets and other formatting options for list items`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Three placeholders each separated from previous by a space that allows a short-hand version of `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`list-style-type`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`list-style-image`}</code>{`, and `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`list-style-image`}</code>{`.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Use `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`none`}</code>{` to remove any bullets or enumeration styling. See Duckett, pp.333–336.`}</td>
          </tr>
        </tbody>
      </table>
      <p>{`Note that when working with basic typography we can really only work with fonts that most users will have on their computers already. These “web safe” fonts are listed on pages 269–270 in the Duckett text. Later in this module we’ll talk about how to diversify and use a wider selection of fonts.`}</p>
      <p>{`Intentionality and appeal can be applied to typography by paying attention to the spacing within blocks such as kerning and leading. Note that in CSS, kerning is controlled by the letter-spacing property, but applies to the whole element selected; therefore, we don’t yet have as much control of kerning without some nasty JavaScript hacks at this point. Leading, on the other hand, is controlled by the line-height property and is a valuable property to apply to make text readable.`}</p>
    </Subpage>
    <Subpage slug="fine-tuning-type-spacing" mdxType="Subpage">
      <h3>{`Fine-Tuning Space Around Type`}</h3>
      <Callout number="7.3" term="Space between blocks..." color="alternate" mdxType="Callout">
        <p>{`...can be controlled using `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`margin-top`}</code>{` and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`margin-bottom`}</code>{`.`}</p>
      </Callout>
      <p>{`In addition to the styles discussed in Duckett, I find two additional properties helpful when fine-tuning text: margin-bottom and margin-top. These both control white space, or margin (which we’ll look at in much more detail in an upcoming module). We use a number followed immediately by a unit (`}<code parentName="p" {...{
          "className": "language-text"
        }}>{`px`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`em`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`%`}</code>{`, etc.) to specify the desired spacing. So while leading controls space inside blocks (space between lines of text in a block), margin controls space outside blocks (space between blocks).`}</p>
      <p>{`Because browsers apply a default set of styles to content, I recommend that you get in the habit of reseting margin whenever you style a block of text, simply by setting `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`margin-bottom`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`margin-top`}</code>{` to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`0`}</code>{`. This clears out the default spacing and gives you control over it which will make your typography much more consistent across browsers, which have different default settings.`}</p>
      <p>{`So you start with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`0`}</code>{` and specify the desired spacing from there, checking each adjustment in a browser to ensure the desired effect is taking place.`}</p>
    </Subpage>
    <Subpage slug="pseudo-class-selectors" mdxType="Subpage">
      <h3>{`Refining Selections with Pseudo-Class Selectors`}</h3>
      <Callout number="7.4" term="The ':hover' pseudo-class..." color="alternate" mdxType="Callout">
        <p>{`...allows us to style links or other elements specifically when the user has hovered their mouse over them.`}</p>
      </Callout>
      <p>{`One last bit of content styling we want to consider is how to make links look one way by default and have a different look when the user hovers over them with their mouse cursor. This is accomplished simply by using two different CSS rules; one that styles just normal `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tags and one that selects using the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`:hover`}</code>{` pseudo-class like this:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`a`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token property"
            }}>{`color`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` blue`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token property"
            }}>{`text-decoration`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` none`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`a:hover`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token property"
            }}>{`text-decoration`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` underline`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`This sets a blue color and removes underlines from links in their default state. But when a user hovers over the link, an underline appears. You can use this model to create any kind of variation for the style of the hovered link.`}</p>
      <p>{`Other pseudo-class selectors include:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`:focus`}</code>{` — sets the style when an interactive element has focus such as through click or keyboard navigation.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`:active`}</code>{` — sets the style when an interactive element has been activated such as through a click.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`:first-child`}</code>{` — added to an element filters to select on the instance of that element that is the first child of a container.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`:last-child`}</code>{` — inverse of `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`:first-child`}</code>{`.`}</li>
      </ul>
      <p><strong parentName="p">{`Note:`}</strong>{` The `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`:hover`}</code>{` pseudo-class can be used on any HTML element, not just `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tags. But use it with caution so that you don't mislead users or break normal web conventions with fanciness.`}</p>
    </Subpage>
    <Subpage slug="custom-fonts" mdxType="Subpage">
      <h3>{`Using Custom Fonts`}</h3>
      <Callout number="7.5" term="We can specific additional fonts in our websites..." color="alternate" mdxType="Callout">
        <p>{`...thanks to open licensing and the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`@font-face`}</code>{` declaration in CSS.`}</p>
      </Callout>
      <p>{`Our browsers allow much more flexibility with fonts today than they used to.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp. 271–272 in Ch. 12 of Duckett`}</p>
        <small>for a survey of some methods for implementing creative typography.</small>
      </Callout>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp. 277–278 in Ch. 12 of Duckett`}</p>
        <small>for more information about the <code>@font-face</code> declaration and how to use custom fonts in your style sheets.</small>
      </Callout>
      <p>{`Note, however, that font usage is a complicated issue—much more than we have time to get into, but here are the key logistics. Computers must "install" a font in order for software to implement and render it in some fashion. Most fonts are prepared with a very specific use in mind and have licenses that restrict it accordingly. This is to protect the creative work of typographers from illegitimate copies that rob them of profit for their work.`}</p>
      <p>{`So while we can simply place a font on our server and link to it in our style sheets, this could be a breech of the font’s license and thus a form of digital piracy. Therefore, while we have the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`@font-face`}</code>{` property and can use it to import and use more fonts than the typical web safe set, we `}<em parentName="p">{`CANNOT`}</em>{` just use any font we have on our computer. We must be sure to use only fonts that have licenses that allow use on websites.`}</p>
    </Subpage>
    <Subpage slug="google-fonts" mdxType="Subpage">
      <h3>{`The Google Fonts API`}</h3>
      <Callout number="7.6" term="Google makes working with custom fonts easier..." color="alternate" mdxType="Callout">
        <p>{`...thanks to the services available at fonts.google.com.`}</p>
      </Callout>
      <p>{`Specifying our own custom fonts requires understanding licensing and ensuring we have all the proper formats and correct syntax in our `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`@font-face`}</code>{` declarations. More than a few have found this challenging. Thankfully, some great work is being done at Google to make open license fonts easier to use. Visit `}<a parentName="p" {...{
          "href": "http://fonts.google.com"
        }}>{`fonts.google.com`}</a>{` to browse a large library of fonts, many of which can be downloaded and installed during your design phase, or linked into your website.`}</p>
      <p>{`In order to work with fonts you find on Google Fonts from within a Figma mockup file, you must first download and install the fonts. A video a little later in this lesson will demonstrate this process.`}</p>
      <h4>{`Using the Google Fonts API in a Site`}</h4>
      <p>{`Here is a suggested process for choosing and using fonts from this service:`}</p>
      <ol>
        <li parentName="ol">{`Visit `}<a parentName="li" {...{
            "href": "http://fonts.google.com"
          }}>{`fonts.google.com`}</a>{`.`}</li>
        <li parentName="ol">{`Search for fonts that might work for your design and click the plus icon for any such font to add it to a collection.`}</li>
        <li parentName="ol">{`Find the Collection panel in the lower portion of the screen and click "arrow" icon in the upper right to see all your fonts side-by-side.`}</li>
        <li parentName="ol">{`Back in the Collection panel for more options for using fonts:`}</li>
      </ol>
      <ul>
        <li parentName="ul">{`Click the "download" icon in the upper right to download the fonts in your collection. Install them on your system (using Font Book on a Mac) in order to use them in design software such as Figma.`}</li>
        <li parentName="ul">{`On the "Customize" tab you can select specific weights and styles for reach font you'd like to use in a website. Note these weights as well for use in the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`font-weight`}</code>{` CSS setting.`}</li>
        <li parentName="ul">{`On the "Embed" tab you can use either the "Standard" `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<link>`}</code>{` tag to link to your styles as external stylesheets from an HTML document. I prefer to switch to the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`@import`}</code>{` option and place the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`@import url...`}</code>{` bit directly into a stylesheet.`}</li>
        <li parentName="ul">{`With a Google Fonts stylesheet linked you can then use the provided settings under "Specify in CSS" to set an element to a particular font.`}</li>
      </ul>
      <p>{`While the library may be somewhat limited, there are still a large number of quality fonts. You will find this much easier to use while you're getting used to web design early on.`}</p>
      <p><em parentName="p">{`NOTE:`}</em>{` There are many sites and options for obtaining free web fonts such as fontsquirrel.com and fonts.com. There are also excellent paid services for delivering high quality fonts to a website such as `}<a parentName="p" {...{
          "href": "https://typekit.com/"
        }}>{`Adobe Typekit`}</a>{` and `}<a parentName="p" {...{
          "href": "https://www.fonts.com/web-fonts"
        }}>{`fonts.com`}</a>{`. These services might appear to cost an arm and a leg, but better typography means a better web and ensures appropriate pay for the hard work that goes into designing a nice font.`}</p>
    </Subpage>
    <Subpage slug="matching-font-sizes" mdxType="Subpage">
      <h3>{`Matching Font Sizes to Your Comp`}</h3>
      <Callout number="7.7" term="Match font sizes in CSS..." color="alternate" mdxType="Callout">
        <p>{`...with points from a comp by setting the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`font-size`}</code>{` on html to 10px. Then the font size on an element can be the font size in the comp divided by 10 (move the decimal one digit to the left).`}</p>
      </Callout>
      <p>{`In CSS we strongly prefer using the flexible `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`em`}</code>{` unit. However, browsers now support an even better unit called `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`rem`}</code>{`. Where `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`em`}</code>{` units were sized relative to the closest parent element, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`rem`}</code>{` sizes based on the `}<em parentName="p">{`root`}</em>{` size.`}</p>
      <p>{`But if you are working from a design comp in software such as Figma you will likely be using points or pixels for fonts. Converting to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`em`}</code>{`s can be a bit of a pain, unless you use this little trick:`}</p>
      <ul>
        <li parentName="ul">{`Set the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`font-size`}</code>{` on `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`html`}</code>{`, the root element, to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`10px`}</code>{`.`}</li>
        <li parentName="ul">{`Set the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`font-size`}</code>{` for elements using the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`rem`}</code>{` unit to the font size in your comp divided by 10. So a font size of `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`14pt`}</code>{` in your comp would be set to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`1.4rem`}</code>{` in your CSS; just move the decimal one digit to the left.`}</li>
      </ul>
      <p>{`Watch this demonstration of how to create content styles based on all we've discussed so far in this lesson:`}</p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/H0TZDDqhRO0" frameborder="0" allowfullscreen></iframe>
    </Subpage>
    <Subpage slug="grids-and-layout-design" mdxType="Subpage">
      <h3>{`Grids and Layout Design`}</h3>
      <p>{`One last element to address in the overall process of planning a website is the prototype.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp.42–43 in Ch. 1 of Miller.`}</p>
      </Callout>
      <p>{`But in order to create a functional prototype, we benefit from first creating design mockups, which aim to add style and appeal to the wireframes we built earlier in the process. A major portion of this task involves determining an effective grid system and using space well.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read Chapter 3 from Miller.`}</p>
      </Callout>
      <p>{`Finally, as we think about designing our own mockups we can finish off chapters we started earlier on web design style and elements of web design.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp.158–177 in Ch. 5 of Miller
and pp.138–145 in Ch. 4 of Miller.`}</p>
      </Callout>
    </Subpage>
    <Subpage slug="study-tools" mdxType="Subpage">
      <h3>{`Study Tools`}</h3>
      <p>{`Memorize each of the following CSS properties or values:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`font-family`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`font-size`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`font-weight`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`font-style`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`text-transform`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`text-decoration`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`line-height`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`letter-spacing`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`text-align`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`text-indent`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`text-shadow`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`list-style`}</code></li>
      </ul>
      <p>{`Prepare answers to the following questions as you study for the exam covering this module:`}</p>
      <ul>
        <li parentName="ul">{`Describe ways that white space is used to create effective designs for the web.`}</li>
        <li parentName="ul">{`Explain the purpose a grid system plays in web page design.`}</li>
        <li parentName="ul">{`Describe the elements of scale, depth and dimension, animation, variability, modularity, and background in relation to web design.  `}</li>
      </ul>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      